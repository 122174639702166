import React from 'react';
import type { ReactNode, ComponentProps } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { SpotlightPulse as Pulse } from '@atlaskit/onboarding';

import { useHoverNudgeTarget } from './useHoverNudgeState';

export const BorderNudge = ({
	children,
	target,
	customStyles,
}: {
	children: ReactNode;
	target: string;
	customStyles?: React.CSSProperties;
}) => {
	const currentTarget = useHoverNudgeTarget();

	return (
		<Pulse
			pulse={currentTarget === target}
			radius={3}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={customStyles ?? {}}
			testId="border-nudge-container"
		>
			{children}
		</Pulse>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NavItemWithNudgeWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	zIndex: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateButtonWithNudgeWrapper = styled(NavItemWithNudgeWrapper)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	marginLeft: '8px !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NavItemWithNudgeFiller = styled.div({
	paddingTop: token('space.050', '4px'),
	paddingBottom: token('space.050', '4px'),
	height: '32px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateButtonFiller = styled(NavItemWithNudgeFiller)({
	padding: '4px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		marginLeft: '0px !important',
	},
});

type WrappableProps = React.PropsWithChildren<{
	target: ComponentProps<typeof BorderNudge>['target'];
	shouldWrap?: boolean;
}>;

export const TopNavItemNudgeWrappable = ({ children, target, shouldWrap }: WrappableProps) => {
	return shouldWrap ? (
		<NavItemWithNudgeWrapper>
			<BorderNudge target={target}>
				<NavItemWithNudgeFiller>{children}</NavItemWithNudgeFiller>
			</BorderNudge>
		</NavItemWithNudgeWrapper>
	) : (
		<>{children}</>
	);
};

export const CreateButtonNudgeWrappable = ({ children, target, shouldWrap }: WrappableProps) => {
	return shouldWrap ? (
		<CreateButtonWithNudgeWrapper>
			<BorderNudge target={target}>
				<CreateButtonFiller>{children}</CreateButtonFiller>
			</BorderNudge>
		</CreateButtonWithNudgeWrapper>
	) : (
		<>{children}</>
	);
};

export const BorderNudgeWrappable = ({
	children,
	target,
	shouldWrap,
	customStyles,
}: React.PropsWithChildren<
	WrappableProps & {
		customStyles?: ComponentProps<typeof BorderNudge>['customStyles'];
	}
>) => {
	return shouldWrap ? (
		<BorderNudge target={target} customStyles={customStyles}>
			{children}
		</BorderNudge>
	) : (
		<>{children}</>
	);
};
