/**
 * Checks if the specified locale and the browser's language setting are English.
 * Optionally skips the browser's language check.
 *
 * @param {string} locale - The locale to check. It will be transformed to lowercase for comparison.
 * @param {boolean} [skipBrowserCheck=false] - Whether to skip checking the browser's language setting. Defaults to false.
 * @returns {boolean} - Returns true if:
 *  - The passed locale starts with 'en' and navigator language setting starts with 'en'.
 *  - The passed locale starts with 'en' and either skipBrowserCheck is true or the navigator object is not available.
 * Returns false otherwise.
 */

export const isLocaleEnglish = (locale: string, skipBrowserCheck: boolean = false): boolean => {
	const englishPrefix = 'en';
	const isLocaleEnglish = locale.toLowerCase().startsWith(englishPrefix);
	// When skipBrowserCheck is true or we are not in a browser environment, only check locale
	if (skipBrowserCheck || !Boolean(window?.navigator)) {
		return isLocaleEnglish;
	}
	// Otherwise, check both locale and navigator language
	return isLocaleEnglish && navigator.language?.toLowerCase().startsWith(englishPrefix);
};
