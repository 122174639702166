import type { FC } from 'react';
import React, { useCallback, useContext, memo } from 'react';
import type { WrappedComponentProps } from 'react-intl-next';
import { defineMessages, injectIntl } from 'react-intl-next';

import { Create } from '@atlaskit/atlassian-navigation';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { AppNavigationContext } from '@confluence/app-navigation-context';

const i18n = defineMessages({
	CreateButtonText: {
		id: 'app-navigation.create.button',
		defaultMessage: 'Create',
		description: 'button to create a new page',
	},
});

const CreateButtonComponent: FC<
	{
		onClick?: (e: React.MouseEvent<HTMLElement>) => void;
		href?: string;
	} & WrappedComponentProps
> = memo(({ onClick = () => {}, href, intl }) => {
	const { resetStickySearchRef } = useContext(AppNavigationContext);
	const handleCreateClick = useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			onClick(e);
			resetStickySearchRef?.current?.();
		},
		[onClick, resetStickySearchRef],
	);

	return (
		<SpotlightTarget name="nav-create-v2">
			<Create
				onClick={handleCreateClick}
				href={href}
				text={intl.formatMessage(i18n.CreateButtonText)}
				testId="globalCreate"
			/>
		</SpotlightTarget>
	);
});

export const CreateButton = injectIntl(CreateButtonComponent);
