export const HOVER_TARGET = {
	INVITE: 'invite',
	CREATE: 'create',
	RECENT_PAGES: 'recent-pages',
	SPACES: 'spaces',
	PAGES: 'pages',
	CREATOR_ONBOARDING_TEMPLATE: 'creator-onboarding-template',
	PREMIUM_TRIAL: 'premium-trial',
	INITIAL_STATE: '',
};
