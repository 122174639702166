import gql from 'graphql-tag';

export const UserPersonalizationQuery = gql`
	query PersonalizationQuery($userId: String!) @experimental {
		experimentalUserPersonalization(userId: $userId) {
			attributes {
				name
				value
			}
		}
	}
`;
