import { addDays, isFuture } from 'date-fns';
import { useContext } from 'react';

import { useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';

import { isLocaleEnglish } from '../../utils/isLocaleEnglish';
import { useGetOnboardingState, deserializeState } from '../onboardingState/onboardingState';
import {
	ONBOARDING_INTENT_EXPERIENCES,
	ONBOARDING_EXPERIENCES,
} from '../../constants/onboarding-state/onboarding-constants';
import { useOnboardingEligible } from '../useOnboardingEligible/useOnboardingEligible';
import { useUserByLicensedProducts } from '../useUserByLicensedProducts/useUserByLicensedProducts';

const AUTO_DISMISS_DAYS = 3;

/**
 * Eligible to show the takeover experience if:
 * - Land users, and
 * - Not dismissed by users, and
 * - Not exceed 3 days since user see the screen at first time, and
 * - english locale
 */
export function useOnboardingNewHomeTakeoverEnabled() {
	const { isSiteAdmin } = useContext(SPAViewContext);
	const { isEstablishedUser, isEstablishedUserReady, eligibleNewUser } = useOnboardingEligible();

	const { locale } = useSessionData();
	const isEnglishLocale = isLocaleEnglish(locale);

	const skipQueries = !isSiteAdmin || !isEnglishLocale;

	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		Object.values({
			IS_FOUNDING_ADMIN: ONBOARDING_EXPERIENCES.IS_FOUNDING_ADMIN,
			...ONBOARDING_INTENT_EXPERIENCES,
		}),
		skipQueries,
	);

	const {
		isFoundingAdmin,
		onboardingIntentExperienceLandViewedAt,
		onboardingIntentExperienceLandClosed,
	} = deserializeState(onboardingStateData);

	const exceededAutoDismissDays = hasExceededAutoDismissDaysSinceLastView(
		onboardingIntentExperienceLandViewedAt,
	);

	const {
		isLandUser,
		loading: licensedProductsLoading,
		error: licensedProductsError,
	} = useUserByLicensedProducts({
		skip:
			skipQueries ||
			!isFoundingAdmin ||
			onboardingIntentExperienceLandClosed ||
			exceededAutoDismissDays,
	});

	if (licensedProductsLoading || onboardingStateLoading) {
		return {
			isEnabled: false,
			loading: true,
		};
	}

	if (licensedProductsError || onboardingStateError) {
		return {
			isEnabled: false,
			error: licensedProductsError || onboardingStateError,
		};
	}

	// Either pass the auto dismiss days or user has manually dismissed the experience
	const shouldDismiss = exceededAutoDismissDays || onboardingIntentExperienceLandClosed;

	const isNotEstablishedUser = isEstablishedUserReady && !isEstablishedUser;
	const isUserEligible =
		isFoundingAdmin &&
		isLandUser &&
		/**
		 * Either the user start as not-established user, or
		 * if the user has turned to established user but
		 * they have viewed the experience at the first time and since then the experience has not be dismissed
		 */
		Boolean(eligibleNewUser || isNotEstablishedUser || onboardingIntentExperienceLandViewedAt) &&
		!shouldDismiss;

	const isEligibleToShow = isEnglishLocale && isUserEligible;

	return {
		isEnabled: isEligibleToShow,
		loading: false,
		onboardingIntentExperienceLandViewedAt,
	};
}

function hasExceededAutoDismissDaysSinceLastView(viewedAt: string | undefined) {
	if (!viewedAt) {
		return false;
	}

	return !isFuture(addDays(new Date(viewedAt), AUTO_DISMISS_DAYS));
}
