import type { Action } from 'react-sweet-state';
import { createHook, createStore, createActionsHook, createStateHook } from 'react-sweet-state';
import { HOVER_TARGET } from './constants/HoverTarget';

type State = {
	target: (typeof HOVER_TARGET)[keyof typeof HOVER_TARGET];
};

const actions = {
	setTarget:
		(target: string): Action<State> =>
		({ setState }) => {
			// mutate state synchronously
			setState({
				target,
			});
		},
	clearTarget:
		(): Action<State> =>
		({ dispatch }) => {
			void dispatch(actions.setTarget(HOVER_TARGET.INITIAL_STATE));
		},
};
const Store = createStore({
	// value of the store on initialization
	initialState: {
		target: HOVER_TARGET.INITIAL_STATE,
	},
	// actions that trigger store mutation
	actions,
	// optional, unique, mostly used for easy debugging
	name: 'hoverNudge',
});

export const useHoverNudgeState = createHook(Store);

export const useHoverNudgeTarget = createStateHook(Store, {
	selector: ({ target }) => target,
});

export const useHoverNudgeActions = createActionsHook(Store);
